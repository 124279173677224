<template>
  <el-main>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
      <el-form-item label="3个月合作金额：" prop="three">
        <el-input v-model="ruleForm.three"></el-input>
      </el-form-item>
      <el-form-item label="6个月合作金额：" prop="six">
        <el-input v-model="ruleForm.six"></el-input>
      </el-form-item>
      <el-form-item label="12个月合作金额：" prop="year">
        <el-input v-model="ruleForm.year"></el-input>
      </el-form-item>
    </el-form>
    <Preservation @preservation="Preservation"></Preservation>
  </el-main>
</template>

<script>
import Preservation from '@/components/preservation';
export default {
  components: {
    Preservation
  },
  data () {
    return {
      ruleForm: {
        three: '',
        six: '',
        year: ''
      },
      rules: {
        three: [{ required: true, message: '请输入3个月合作金额', trigger: 'blur' }],
        six: [{ required: true, message: '请输入6个月合作金额', trigger: 'blur' }],
        year: [{ required: true, message: '请输入12个月合作金额', trigger: 'blur' }],
      }
    }
  },
  created () {
    this.getInfo()
  },
  methods: {
    getInfo () {
      this.$axios.post(this.$api.gateway.setting.info)
        .then(res => {
          if (res.code === 0) {
            if (res.result.data) {
              this.ruleForm = res.result.data_info
            }
          } else {
            this.$message.error(res.msg)
          }
        })
    },
    Preservation () {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let obj = {
            data: this.ruleForm
          }
          this.$axios.post(this.$api.gateway.setting.add, obj)
            .then(res => {
              if (res.code === 0) {
                this.$message.success('保存成功')
              } else {
                this.$message.error(res.msg)
              }
            })
        }
      })
    }
  },
}
</script>

<style lang="less" scoped>
.el-main {
  background-color: #fff;
  min-height: 100%;

  .el-form {
    .el-input {
      width: 700px;
    }
  }
}
</style>